import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import "./App.css";
import Sidenav1 from "./Component/sidenav";
import Overview from "./Component/Overview";
import Login from "./Component/Login";
import Signup from "./Component/Signup";
import AddProduct from "./Component/AddProduct";
import ProductView from "./Component/ProductView";
import Order from "./Component/Order";
import CouponComponent from "./Component/Coupon";
import OrderView from "./Component/OrderView";
import ClientProfile from "./Component/ClientProfile";
import PrivateRoute from "./PrivateRoutes";
import ContactDetails from "./Component/Client.js";

export default function App() {
  const location = useLocation();
  const excludeRoutes = ["/", "/Signup"];
  const shouldRenderSidenav = !excludeRoutes.includes(location.pathname);
  const userData = JSON.parse(localStorage.getItem("userData"));

  return (
    <>
      <div className="App">
        {shouldRenderSidenav && (
          <div className="sidenav-container">
            <Sidenav1 />
          </div>
        )}

        <main>
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<Login />} />
            <Route path="/Signup" element={<Signup />} />

            {/* Private Routes */}
            {userData ? (
              <>
                <Route path="/product" element={<PrivateRoute><AddProduct /></PrivateRoute>} />
                <Route path="/dashboard" element={<PrivateRoute><Overview /></PrivateRoute>} />
                <Route path="/viewdetails" element={<PrivateRoute><ProductView /></PrivateRoute>} />
                <Route path="/orderview/:data" element={<PrivateRoute><OrderView /></PrivateRoute>} />
                <Route path="/order" element={<PrivateRoute><Order /></PrivateRoute>} />
                <Route path="/client" element={<PrivateRoute><ContactDetails /></PrivateRoute>} />
                <Route path="/clientProfile/:data" element={<PrivateRoute><ClientProfile /></PrivateRoute>} />
                <Route path="/coupon" element={<PrivateRoute><CouponComponent /></PrivateRoute>} />
              </>
            ) : (
            
              <Route path="*" element={<Navigate to="/" />} />
            )}
          </Routes>
        </main>
      </div>
    </>
  );
}