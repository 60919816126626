import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import Moment from "react-moment";

export default function OrderView() {
  const ReactApi = "https://api.gdswellness.com";
  const [Order, setOrder] = useState(null);
  const [ActiveImage, setActiveImage] = useState(null);
  const [ProductData, setProductData] = useState([]);
  
  const location = useLocation();
  let data = location.state?.id || null;

  useEffect(() => {
    getOrder();
  }, []);

  useEffect(() => {
    if (Order && ProductData.length > 0) {
      const productDetails = ProductData.filter(product =>
        Order?.ProductDetails?.some(
          orderDetail => orderDetail?.productId === product._id
        )
      );

      if (productDetails.length > 0 && productDetails[0]?.productimage?.length > 0) {
        // Set the default active image as the first image of the first matched product
        setActiveImage(productDetails[0]?.productimage[0]);
      }
    }
  }, [Order, ProductData]);

  const getOrder = async () => {
    try {
      const product = await axios.get(`${ReactApi}/api/product/getallProduct`);
      setProductData(product.data.data);

      let res = await axios.get(`${ReactApi}/api/order/getorderbyid/${data}`);
      if (res.status === 200) {
        setOrder(res.data);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const navigate = useNavigate();
  
  const handleViewImage = img => {
    setActiveImage(img);
  };

  const handleNavigate = () => {
    navigate("/order");
  };

  return (
    <div className="row m-auto mx-5">
      <div className="row mt-2 cl">
        <div className="col-md-2  me-auto">
          <IoArrowBackCircleSharp className="fs-2" onClick={handleNavigate} />
        </div>
      </div>
      <div>
       
        <p className="subheading">
          Customer Name :  <span className="title">{Order?.fName} {Order?.lName}</span>
        </p>
        
        <p className="subheading">
          Contact : <span className="title">{Order?.contact}</span>
        </p>
        <p className="subheading">
          OrderId : <span className="title">{Order?.OrderId}</span>
        </p>
        <p className="subheading">
          Total Amount : <span className="title">${Order?.amount}</span>
        </p>
        <p className="subheading">
          Order Placed At : 
          <span className="title mx-2">
            <Moment format="MMMM Do YYYY, h:mm A">{Order?.createdAt}</Moment>
          </span>
        </p>
        <p className="subheading">
          Address :
          <span className="title"> {
            ' '}
            {Order?.flatAddress} {Order?.address}, {Order?.city}, {Order?.state} - {Order?.zipcode}, {Order?.country}
          </span>
        </p>
      </div>

      {ProductData.filter(product =>
        Order?.ProductDetails?.some(
          orderDetail => orderDetail?.productId === product._id
        )
      ).map(daata => (
        <div className="row mt-3" key={daata._id}>
          <div className="col-md-6 m-auto">
            <div className="row">
              <div className="col-md-2">
                {daata?.productimage?.map(Ele => (
                  <div key={Ele} className="col-md-2 p-1 thumbnail-container">
                    <img
                      className="thumbnail-image"
                      onMouseEnter={() => handleViewImage(Ele)}
                      width={100}
                      height={100}
                      src={`${ReactApi}/Product/${Ele}`}
                      alt=""
                    />
                  </div>
                ))}
              </div>

              <div className="main-image-container col-md-10 ">
                <div className="image-zoom-container">
                  <img
                    className="view-image"
                    src={`${ReactApi}/Product/${ActiveImage}`}
                    alt="Active product"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="product-details">
              <h2 className="product-name">{daata?.productName}</h2>
              <p className="row">
                <span className="real-price me-2 col-md-2">
                  ${daata?.realPrice}
                </span>
                <span className="offer-price col-md-2">
                  ${daata?.offerPrice}
                </span>
              </p>
              <p className="product-subtitle">{daata?.subtitle}</p>
              <p className="product-category">
                <strong>Category:</strong> {daata?.category}
              </p>
              <p className="product-unit">
                <strong>Unit:</strong> {daata?.unit}
              </p>
              <p className="product-unit">
                <strong>Product quantity:</strong>{" "}
                {
                  Order?.ProductDetails?.find(
                    detail => detail?.productId === daata._id
                  )?.quantity
                }
              </p>
              <p className="product-packsize">
                <strong>Pack Size:</strong> {daata?.packsize}
              </p>
            </div>
          </div>
          <div dangerouslySetInnerHTML={{ __html: daata?.discription }}></div>
        </div>
      ))}
    </div>
  );
}
